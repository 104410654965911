/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}

.fade-transition {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}
.Dialogtitle{
  color: #138B7F;
  font-size: 30px !important;
  text-transform: capitalize !important;
}
.Dialogcontent p{
  font-size: 16px !important;
  color: #464b4a;
  text-transform: capitalize !important;
}

/* #about {
	background: linear-gradient(-45deg, #1f93ce, #47e9cd, #ffca0bb8, #e98447, #ff9d6d, #ff6363);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
} */

