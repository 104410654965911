/* Wrapper to keep everything fixed */
.chatbot-wrapper {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 2;
}

/* Floating chatbot icon with rainbow animation and ripple effect */
.chatbot-icon {
    width: 60px;
    height: 60px;
    background: #ff6363;
    color: white;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;
    animation: rainbow 5s infinite;
    position: relative; /* Required for ripple effect */
}

.chatbot-icon:hover {
    transform: scale(1.1);
}

/* Ripple effect */
.chatbot-icon::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: inherit;
    opacity: 0.5;
    animation: ripple 2s infinite ease-out;
    z-index: -1;
}

@keyframes ripple {
    0% {
        transform: scale(1);
        opacity: 0.5;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
}

/* Rainbow color animation */
@keyframes rainbow {
    0% { background-color: #ff6363; } 
    14% { background-color: #ffa500; } 
    28% { background-color: #ffff00; }
    42% { background-color: #00ff00; } 
    57% { background-color: #0000ff; } 
    71% { background-color: #4b0082; } 
    85% { background-color: #8a2be2; } 
    100% { background-color: #ff6363; } 
}

/* Chatbot container */
.chatbot-container {
    width: 350px;
    height: 450px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 20px;
    right: 20px;
    overflow: hidden;
}

/* Chat header */
.chat-header {
    background: #ff6363;
    color: white;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close-button {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

/* Chat window */
.chat-window {
    flex-grow: 1;
    padding: 10px;
    overflow-y: auto;
    background: #f9f9f9;
}

/* Chat messages */
.message {
    display: flex;
    margin-bottom: 10px;
}

.user-message {
    justify-content: flex-end;
}

.bot-message {
    justify-content: flex-start;
}

.message-content {
    padding: 8px 12px;
    border-radius: 8px;
    max-width: 75%;
    font-size: 14px;
}

.user-message .message-content {
    background: #ff6363;
    color: white;
    border-top-right-radius: 0;
}

.bot-message .message-content {
    background: #e0e0e0;
    color: black;
    border-top-left-radius: 0;
}

/* Input box */
.input-container {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ccc;
    background: white;
}

.input-box {
    flex-grow: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

.send-button {
    margin-left: 8px;
    padding: 8px 15px;
    border: none;
    background: #ff6363;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
}

.send-button:hover {
    background: #ec3535;
}