/* Wrapper to keep everything fixed */
.call-wrapper {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 5;
}

/* Floating call icon with rainbow animation and ripple effect */
.call-icon {
    width: 60px;
    height: 60px;
    background: #28a745;
    color: white;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;
    position: relative;
    overflow: hidden;
    animation: rainbow 5s infinite;
}

/* Hover effect */
.call-icon:hover {
    transform: scale(1.1);
}

/* Ripple effect */
.call-icon::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: inherit;
    opacity: 0.5;
    animation: ripple 2s infinite ease-out;
    z-index: -1;
}

/* Call popup container (with paper effect) */
.callbody {
    width: 400px;
    background: white;
    border-radius: 12px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3); /* Paper effect */
    padding: 0px;
    position: fixed;
    bottom: 20px;
    left: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    animation: fadeIn 0.3s ease-in-out;
    min-height: 200px;
}

.call-header {
    background: #639aff;
    color: white;
    padding: 10px;
    border-radius: 12px 12px 0 0;
    text-align: center;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
/* Close button */
.call-close-button {
    position: absolute;
    top: 6px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #fff;
    transition: color 0.2s;
}

.call-close-button:hover {
    color: #000;
}

.input-call-container {
    display: flex;
    padding: 10px;
    /* border-top: 1px solid #ccc; */
    background: white;
    border-radius: 0 0 12px 12px;
}

/* Input box */
.input-call-box {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    width: 100%;
}

/* Make Call button */
.send-button {
    padding: 10px;
    border: none;
    background: #28a745;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    transition: background 0.2s;
}

.send-button:hover {
    background: #218838;
}

/* Ripple animation */
@keyframes ripple {
    0% {
        transform: scale(1);
        opacity: 0.5;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
}

/* Rainbow color animation */
@keyframes rainbow {
    0% { background-color: #28a745; } 
    14% { background-color: #ffa500; } 
    28% { background-color: #ffff00; }
    42% { background-color: #00ff00; } 
    57% { background-color: #0000ff; } 
    71% { background-color: #4b0082; } 
    85% { background-color: #8a2be2; } 
    100% { background-color: #28a745; } 
}

/* Fade-in animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
